var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-2"},[_c('v-card-title',{staticClass:"body-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t(("SHIP_ARRIVAL.HISTORY.STATUS." + (_vm.history.statusType))))+" ")]),_c('v-card-text',{staticClass:"d-flex flex-column"},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex",class:{ 'error--text':
                  _vm.history.voyageUpdate.oldEta !== _vm.history.voyageUpdate.updatedEta }},[_c('b',[_vm._v(_vm._s(_vm.$t('SHIP_ARRIVAL.DETAILS.ETA'))+":")]),_c('div',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.$isoStringDateTimeHumanized(_vm.history.voyageUpdate.oldEta))+" ")]),_c('v-icon',{staticClass:"mx-3",class:{ 'error--text':
                  _vm.history.voyageUpdate.oldEta !== _vm.history.voyageUpdate.updatedEta },attrs:{"x-small":""}},[_vm._v(" fas fa-arrow-right ")]),_c('div',[_vm._v(" "+_vm._s(_vm.$isoStringDateTimeHumanized(_vm.history.voyageUpdate.updatedEta))+" ")])],1),_c('div',{staticClass:"mt-2 d-flex",class:{ 'error--text':
                  _vm.history.voyageUpdate.oldEts !== _vm.history.voyageUpdate.updatedEts }},[_c('b',[_vm._v(_vm._s(_vm.$t('SHIP_ARRIVAL.DETAILS.ETS'))+":")]),_c('div',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.$isoStringDateTimeHumanized(_vm.history.voyageUpdate.oldEts))+" ")]),_c('v-icon',{staticClass:"mx-3",class:{ 'error--text':
                  _vm.history.voyageUpdate.oldEts !== _vm.history.voyageUpdate.updatedEts },attrs:{"x-small":""}},[_vm._v(" fas fa-arrow-right ")]),_c('div',[_vm._v(_vm._s(_vm.$isoStringDateTimeHumanized(_vm.history.voyageUpdate.updatedEts)))])],1),(_vm.history.voyageUpdate.oldVoyageNoImport)?_c('div',{staticClass:"mt-2 d-flex",class:{ 'error--text':
                  _vm.history.voyageUpdate.oldVoyageNoImport !== _vm.history.voyageUpdate.updatedVoyageNoImport }},[_c('b',[_vm._v(_vm._s(_vm.$t('SHIP_ARRIVAL.DETAILS.VOYAGE_IMPORT'))+":")]),_c('div',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.history.voyageUpdate.oldVoyageNoImport)+" ")]),_c('v-icon',{staticClass:"mx-3",class:{ 'error--text':
                  _vm.history.voyageUpdate.oldVoyageNoImport !== _vm.history.voyageUpdate.updatedVoyageNoImport },attrs:{"x-small":""}},[_vm._v(" fas fa-arrow-right ")]),_c('div',[_vm._v(_vm._s(_vm.history.voyageUpdate.updatedVoyageNoImport))])],1):_vm._e(),(_vm.history.voyageUpdate.oldVoyageNoExport)?_c('div',{staticClass:"mt-2 d-flex",class:{ 'error--text':
                  _vm.history.voyageUpdate.oldVoyageNoExport !== _vm.history.voyageUpdate.updatedVoyageNoExport }},[_c('b',[_vm._v(_vm._s(_vm.$t('SHIP_ARRIVAL.DETAILS.VOYAGE_EXPORT'))+":")]),_c('div',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.history.voyageUpdate.oldVoyageNoExport)+" ")]),_c('v-icon',{staticClass:"mx-3",class:{ 'error--text':
                  _vm.history.voyageUpdate.oldVoyageNoExport !== _vm.history.voyageUpdate.updatedVoyageNoExport },attrs:{"x-small":""}},[_vm._v(" fas fa-arrow-right ")]),_c('div',[_vm._v(_vm._s(_vm.history.voyageUpdate.updatedVoyageNoExport))])],1):_vm._e(),(_vm.history.voyageUpdate.oldVessel)?_c('div',{staticClass:"mt-2 d-flex",class:{ 'error--text':
                  _vm.history.voyageUpdate.oldVessel.id !== _vm.history.voyageUpdate.updatedVessel.id }},[_c('b',[_vm._v(_vm._s(_vm.$t('SHIP_ARRIVAL.DETAILS.VESSEL'))+":")]),_c('div',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.history.voyageUpdate.oldVessel.name)+" ("+_vm._s(_vm.history.voyageUpdate.oldVessel.code)+") ")]),_c('v-icon',{staticClass:"mx-3",class:{ 'error--text':
                  _vm.history.voyageUpdate.oldVessel.id !== _vm.history.voyageUpdate.updatedVessel.id },attrs:{"x-small":""}},[_vm._v(" fas fa-arrow-right ")]),_c('div',[_vm._v(_vm._s(_vm.history.voyageUpdate.updatedVessel.name)+"("+_vm._s(_vm.history.voyageUpdate.updatedVessel.code)+")")])],1):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }